import * as React from "react"
import { ProductsStyles } from "../Products/ProductsStyles"
import { StaticImage, getImage } from "gatsby-plugin-image"

const Features = () => {
  return (
    <ProductsStyles>
      <h2>Gallery</h2>
      <div className="flex">
        <div className="left">
          <div className="features__container">
            <aside className="features__item features__item-vertical">
              <StaticImage
                className="features__image--gatsby"
                src="../../images/Truck-Angle_cropped.png"
                alt="Waterfall"
                layout="constrained"
                placeholder="tracedSVG"
                width={800}
                // height={800}
              />
            </aside>
            <aside className=" features__item features__item-vertical">
              <StaticImage
                className="features__image--gatsby"
                src="../../images/Barn.jpg"
                alt="Barn"
                layout="constrained"
                placeholder="tracedSVG"
                width={800}
              />
            </aside>
            <aside className="features__item">
              <StaticImage
                className="features__image--bg"
                src="../../images/Field.jpg"
                alt="Barn"
                layout="constrained"
                placeholder="tracedSVG"
                width={800}
              />
            </aside>
          </div>
        </div>
        <div className="right">
          <div className="features__container">
            <aside className="features__item">
              <StaticImage
                className="features__image--bg"
                src="../../images/Waterfall-Square.png"
                alt="Waterfall"
                layout="constrained"
                placeholder="tracedSVG"
                width={800}
              />
            </aside>
            <aside className="features__item">
              <StaticImage
                className="features__image--bg"
                src="../../images/Field-Sun.jpg"
                alt="Field"
                layout="constrained"
                placeholder="tracedSVG"
                width={800}
              />
            </aside>
            <aside className="features__item features__item-vertical">
              <StaticImage
                className="features__image--gatsby"
                src="../../images/Creek.jpg"
                alt="Field"
                layout="constrained"
                placeholder="tracedSVG"
                width={800}
              />
            </aside>
          </div>
        </div>
      </div>
    </ProductsStyles>
  )
}

export default Features
