import styled from "styled-components"

export const ProductsStyles = styled.section`
  padding: var(--sectionPadding) var(--borderSpacing);
  color: #fff;
  background-color: #000;

  .flex {
    width: calc(100% + 40px);

    display: flex;
    flex-wrap: wrap;

    .left,
    .right {
      flex: 1;

      @media (max-width: 768px) {
        min-width: 100%;
      }
    }
  }

  .features__container {
    transform: translateX(-30px);
    max-width: 1230px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    /* grid-template-columns: 50% 50%; */
    grid-template-columns: 100%;
    /* grid-template-columns: 33% 33% 33%; */
    /* grid-template-rows: 800px auto auto auto; */
    grid-template-rows: auto;
    /* gap: 20px; */
    /* grid-auto-flow: row dense; */
  }

  .features__image {
    &--bg {
      min-height: 250px;
      /* height: 100%; */
    }
    &--gatsby {
      min-height: 250px;
    }
  }

  .features__item {
    padding: 10px;
  }
`
