import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"
import { StaticImage, getImage } from "gatsby-plugin-image"
// import downloadFile from "../../files/TN_Dirt_Devil_GAIA_Coords.gpx"

const BasicTextModule = () => {
  return (
    <BasicTextModuleStyles>
      <div className="container">
        <div className="flex">
          <div className="left">
            <h2>Download the Gaia GPS Route</h2>
            <p style={{ marginBottom: "10px" }}>
              Just import the route into your{" "}
              <a
                href="https://www.gaiagps.com/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                GAIA GPS App
              </a>
              , and make sure to stay on the trail!
            </p>
            <p style={{ marginBottom: "30px" }}>
              Do NOT venture off of the route or you will end up on private
              property!
            </p>
            <a href="DirtDevil.gpx" download style={{ color: "transparent" }}>
              <Button text="Download Route"></Button>
            </a>
          </div>
          <div
            className="right"
            style={{ padding: "20px", borderRadius: "25px" }}
          >
            <StaticImage
              src="../../images/Private-Property.jpg"
              alt="Private Property"
              layout="constrained"
              placeholder="tracedSVG"
              // objectPosition={"0% 50%"}
              imgClassName="basictext__image"
              width={800}
            />
          </div>
        </div>
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
