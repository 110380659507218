import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles>
      <StaticImage
        className="perks__image--bg"
        src="../../images/Rocky.png"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
        width={1300}
      />
      <div className="perks__image--overlay"></div>
      <div className="container">
        <Perk
          title="Stay On The Trail"
          content="Venturing off the trail will quickly land you in private property.  Be respectful of the locals."
        ></Perk>
        <Perk
          title="Keep It Legal"
          content="These are all country roads so your vehicle must be street legal."
        ></Perk>
        <Perk
          title="Water Crossings"
          content='There are a few water crossings, stick to the "trails" or you might get stuck and/or end up on private land.'
        ></Perk>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
